import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], onApplicationSettingsClick, showSubmenu, ...other }) {
  console.log("Nav Section",data);

  return (
    <Box {...other}>
    <List disablePadding sx={{ p: 1 }}>
      {data.map((item) => (
        <NavItem
          key={item.title}
          item={item}
          onApplicationSettingsClick={onApplicationSettingsClick} // Pass the function
          showSubmenu={showSubmenu} // Pass the state
        />
      ))}
    </List>
  </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item,onApplicationSettingsClick, showSubmenu }) {
  const { title, path, icon, info, submenu, hasSubmenu } = item;

  if (hasSubmenu) {
    return (
      <Box>
      <StyledNavItem
        sx={{ color: 'text.primary', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={onApplicationSettingsClick} // Use the passed function
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        <span>{showSubmenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</span>
      </StyledNavItem>
      <List disablePadding>
        {showSubmenu && // Show submenu based on the state
          submenu.map((submenuItem) => (
            <NavItem
              key={submenuItem.title}
              item={submenuItem}
              onApplicationSettingsClick={onApplicationSettingsClick} // Pass the function
              showSubmenu={showSubmenu} // Pass the state
            />
          ))}
      </List>
    </Box>
    );
  }

  if (path) {
    return (
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </StyledNavItem>
    );
  }

  return (
    <StyledNavItem sx={{ color: 'text.primary' }}>
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
}
