import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, List, ListItem, ListItemText, Divider, Box, Alert } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import DashboardServices from '../services/DashboardServices';




// ----------------------------------------------------------------------

export default function DashboardAppPage() {

  const theme = useTheme();
  const location = useLocation();
  const { state } = location;

const [countTop, setCountTop] = useState({});
const [recentUsers, setRecentUsers] = useState([]);
const [recentPayments, setRecentPayments] = useState([]);
const [recentPosts, setRecentPosts] = useState([]);


const getTopCount = async() => {
  // setCustomLoader(true);
  DashboardServices.getDashboardList()
    .then((response) => {
      setCountTop(response.data.data);
      // setCustomLoader(false);
      console.log("Dashboard Data",response.data.data);
    })
    .catch((e) => {
      // setCustomLoader(false);
      console.log(e);
    });
};

const getTopCountList = async() => {
  // setCustomLoader(true);
  DashboardServices.getListOfTop()
    .then((response) => {
      setRecentUsers(response.data.data.RecentUsers);
      setRecentPayments(response.data.data.RecentPayments);
      setRecentPosts(response.data.data.RecentPosts);
      // setCustomLoader(false);
      console.log("Dashboard List",response.data.data);
    })
    .catch((e) => {
      // setCustomLoader(false);
      console.log(e);
    });
};


useEffect(() => {
  getTopCount();
  getTopCountList();
}, []);


  useEffect(() => {
    if (state && state.successMessage) {
      toast.success("Login Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [state]);

  const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };
  


  return (
    <>
      <Helmet>
        <title> Dashboard | Carib Bargains </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Ads" total={countTop?.total_post} color="primary" icon={'ant-design:file-image-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Users" total={countTop?.total_users} color="info" icon={'ant-design:team-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
              title="Today Ads"
              total={countTop?.new_product === 0 ? 0 : countTop.new_product}
              color="warning"
              icon={'ant-design:file-text-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Payments" total={234} color="error" icon={'ant-design:pay-circle-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Alert icon={false} severity="info" variant="filled">Recent Users</Alert>
            {recentUsers.map((user, index) => (
            <List sx={style} component="nav" aria-label="mailbox folders">
              <ListItem button  sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}>
                <ListItemText primary={user.name}  primaryTypographyProps={{
                    color: 'primary',
                    fontWeight: 'medium',
                    variant: 'body2',
                  }}/>
              </ListItem>
            </List>
               ))}
           </Grid>

           <Grid item xs={12} md={6} lg={4}>
            <Alert icon={false} severity="info" variant="filled">Recent Posts</Alert>
            {recentPosts.map((user, index) => (
            <List sx={style} component="nav" aria-label="mailbox folders">
              <ListItem button>
                <ListItemText primary={user.title} primaryTypographyProps={{
                    color: 'primary',
                    fontWeight: 'medium',
                    variant: 'body2',
                  }} />
              </ListItem>
            </List>
               ))}
           </Grid>

           <Grid item xs={12} md={6} lg={4}>
            <Alert icon={false} severity="info" variant="filled">Recent Payments</Alert>
            {recentPayments.map((user, index) => (
            <List sx={style} component="nav" aria-label="mailbox folders">
              <ListItem button>
                <ListItemText primary={user.userName} primaryTypographyProps={{
                    color: 'primary',
                    fontWeight: 'medium',
                    variant: 'body2',
                  }}/>
              </ListItem>
            </List>
               ))}
           </Grid>
        </Grid>
      </Container>
    </>
  );
}
