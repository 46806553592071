import http from "../http-common";
import authHeader from "./auth-header";

const getAll = () => http.get("/admin/users", { headers: authHeader() });

const getCountry = (id) => http.get(`/api/location/country/${id}`);
const updateCountry = (data) => http.put(`/api/location/update`,data);

const create = (data) => {
  console.log(data);
  return http.post("/api/location/mycountrylist/save", data);
};

const remove = (id) =>  http.post(`/users/delete/${id}`);

const getPaginationWithCountries = (params) => http.get("/api/location/mycountrylist", { params });

const CountriesServcies = {
  getAll,
  getCountry,
  create,
  remove,
  getPaginationWithCountries,
  updateCountry
};

export default CountriesServcies;
