import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import DashboardAppPage from './pages/DashboardAppPage';
import LoginPage from './pages/LoginPage';
import UserPage from './pages/UserPage';
import ProductsPage from './pages/products';
import CompanySettings from './pages/CompanySettings';
import CoinSettings from './pages/CoinSettings';
import NotificationSettings from './pages/NotificationSettings';
import DevSettings from './pages/DevSettings';
import PaymentSettings from './pages/PaymentSettings';
import ApplicationSettings from './pages/ApplicationSettings';
import SMTPSettings from './pages/SMTPSettings';
import PushNotification from './pages/PushNotification';
import Categories from './pages/Categories';
import Countries from './pages/Countries';
import AddCountry from './pages/AddCountry';

// ----------------------------------------------------------------------

export default function Router() {

  const isUserAuthenticated = () => {
    const token = localStorage.getItem("token");
    return !!token; // Return true if token is present, false otherwise
  };


  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" /> },
        {
          path: 'app',
          element: isUserAuthenticated() ? <DashboardAppPage /> : <Navigate to="/login" />,
        },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'smtpsettings', element: <SMTPSettings /> },
        { path: 'companysettings', element: <CompanySettings /> },
        { path: 'coinssettings', element: <CoinSettings /> },
        { path: 'notificationssettings', element: <NotificationSettings /> },
        { path: 'devsettings', element: <DevSettings /> },
        { path: 'paymentsettings', element: <PaymentSettings /> },
        { path: 'applicationsettings', element: <ApplicationSettings /> },
        { path: 'notification', element: <PushNotification /> },
        { path: 'categories', element: <Categories /> },
        { path: 'countries', element: <Countries /> },
        { path: 'addcountries', element: <AddCountry /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
      index: true
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        // { path: '404', element: <Page404 /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return routes;
}
