import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress
} from '@mui/material';
import './customStyles.css';
import AppSettings from '../services/AppSettings';

export default function CompanySettings() {

  const initialUserService = {
    id: "",
    name: "",
    address: "",
    phoneNo: "",
    supportEmail:"",
    skypeId:"",
    registrationYear:""
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUser = () => {
    setCustomLoader(true);
    AppSettings.getCompany()
      .then((response) => {
        setCurrentUser(response.data.data);
        setCustomLoader(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
     getUser();
  }, []);


  const isValidEmail = (email) => {
    // Email validation regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Phone number validation regular expression (adjust as needed)
    const phonePattern = /^\+?\d{10}$|^\+?\d{15}$|^\+?\d+\s?\d{10}$|^\+?\d+\s?\d{15}$/;
    return phonePattern.test(phoneNumber);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };



  
  const updateUser = () => {
    setLoading(true);
    // Check if any of the fields in currentUser are empty
  const isEmptyField = Object.values(currentUser).some(value => value === "");
    if (isEmptyField) {
      // Display a toast message indicating that all fields need to be filled
      toast.error("Please fill in all fields", {
        position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
      return; // Don't proceed with the update if fields are empty
    }
    const isEmailValid = isValidEmail(currentUser.supportEmail);
    const isPhoneNumberValid = isValidPhoneNumber(currentUser.phoneNo);


    // Display error messages for invalid fields
    if (!isEmailValid) {
      toast.error("Invalid email format", {
        position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
      return; 
    }

    if (!isPhoneNumberValid) {
      toast.error("Invalid phone number", {
        position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
      return;
    }


    AppSettings.updateCompany(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success("Success : Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
      });
  };
  
  return (
    <>
      <Helmet>
        <title>Company Setting | Carib Bargains </title>
      </Helmet>

      <Container>

      {customLoader && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999999999999,
          }}
        >
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            visible={customLoader}
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Company Setting
          </Typography>
        </Stack>
        <Card>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
             <div>
            <TextField fullWidth label="Id" id="id" name='id' value={currentUser.id}
                onChange={handleInputChange} hidden/>
            </div>
            <div>
            <TextField fullWidth label="Name" id="name" name='name' value={currentUser.name}
                onChange={handleInputChange} />
            </div>
            <div>
            <TextField fullWidth label="Address" id="address" name='address'  value={currentUser.address}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Phone No" id="phoneNo" name='phoneNo'  value={currentUser.phoneNo}
                onChange={handleInputChange}   
                InputProps={{
                  inputProps: {
                    maxLength: 15 // Set the maximum length to 15
                  }
                }}/>
            </div>
            <div>
            <TextField fullWidth label="Support Email" id="supportEmail" name='supportEmail'  value={currentUser.supportEmail}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Skype Id" id="skypeId" name='skypeId'  value={currentUser.skypeId}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Registration Year" id="registrationYear" name='registrationYear'  value={currentUser.registrationYear}
                onChange={handleInputChange}/>
            </div>
            <div style={{padding:2, margin:5}}>
              <Button variant="contained" onClick={updateUser}disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </div>
        </Box>
        </Card>
      </Container>
    </>
  );
}
