import http from "../http-common";
// import authHeader from "./auth-header";


// const getAll = () => http.get("/admin/users", { headers: authHeader() });
// const getPublicAll = () => http.get("/users");


const get = () => http.get(`/Smtp/get`);
const getCompany = () => http.get(`/Company/get`);
const getCoins = () => http.get(`/CoinManagement/getplan`);
const getNotification = () => http.get(`/Notification/get`);
const getDeveloper = () => http.get(`/Developer/get`);
const getPayment = () => http.get(`/Payment/get`);
const getApplicationSettings = () => http.get(`/Application/get`);


const create = (data) => {
  console.log(data);
  return http.post("/users", data);
};

const update = (data) => http.put(`/Smtp/update`, data);
const updateCompany = (data) => http.put(`/Company/update`, data);
const updateCoins = (data) => http.post(`/CoinManagement/update`, data);
const updateNotification = (data) => http.put(`/Notification/update`,data);
const updateDeveloper = (data) => http.put(`/Developer/update`,data);
const updatePayment = (data) => http.put(`/Payment/update`,data);
const updateApplicationSettings = (data) => http.put(`/Application/update`,data);


const remove = (id) =>  http.post(`/users/delete/${id}`);

const sendTestNotification = (data) => http.post(`/send-notification`,data);

const sendSMTPNotification = (email) => http.post(`/Notification/testnotification/${email}`);

// const sendSMTPNotification = (email) => {
//   return http
//     .post("/Notification/testnotification", {
//       email,
//     })
//     .then((response) => {
//       console.log(response.data);
//       return response.data;
//     });
// };




const AppSettings = {
  get,
  create,
  update,
  remove,
  getCompany,
  updateCompany,
  getCoins,
  updateCoins,
  getNotification,
  updateNotification,
  sendTestNotification,
  getDeveloper,
  updateDeveloper,
  sendSMTPNotification,
  getPayment,
  updatePayment,
  getApplicationSettings,
  updateApplicationSettings
};

export default AppSettings;
