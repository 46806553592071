import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // State for "Remember me" checkbox

  const sendUserData = {
    "email": email,
    "password": password,
    "rememberMe":rememberMe
  }

  useEffect(() => { 
    console.log("Effect triggered");
    // Check if 'rememberMe' is set in localStorage
    const storedRememberMe = localStorage.getItem('rememberMe');
    if (storedRememberMe) {
      // Retrieve stored email and password from localStorage
      const storedEmail = localStorage.getItem('storedEmail');
      const storedPassword = localStorage.getItem('storedPassword');
      console.log(storedEmail,storedPassword)
      // if (storedEmail && storedPassword) {
        setEmail(storedEmail);
        setPassword(storedPassword);
        setRememberMe(true);
      // }
    }
  }, [email, password]); // Update when email or password change
  

  const handleRememberMeChange = (newValue) => { 
    setRememberMe(newValue);
    if (newValue) {
      // Store email and password in localStorage
      localStorage.setItem('storedEmail', email);
      localStorage.setItem('storedPassword', password);
      localStorage.setItem('rememberMe', 'true');
    } 
    else {
      // Clear stored email and password from localStorage
      localStorage.removeItem('storedEmail');
      localStorage.removeItem('storedPassword');
      localStorage.setItem('rememberMe', 'false');
    }
  };

  const isValidEmail = (email) => {
    // Email validation regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleClick = async () => {
    if (!email || !password) {
      // Display a toast message indicating that all fields need to be filled
      toast.error("Please fill in all fields", {
        position: toast.POSITION.TOP_RIGHT
      });
      return; // Don't proceed with the update if fields are empty
    }
  
    if (!isValidEmail(email)) {
      // Display a toast message indicating that the email format is incorrect
      toast.error("Invalid email format", {
        position: toast.POSITION.TOP_RIGHT
      });
      return; // Don't proceed with the update if email format is incorrect
    }

    setLoading(true);
    try {
      const response = await fetch('http://62.171.153.83:8080/carib-bargains-api-new/auth/login', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(sendUserData),
      });
  
      if (!response.ok) {
        // If response status is not ok (invalid credentials)
        toast.error("Invalid Credentials!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
        return;
      }
  
      // Successful login
      const result = await response.json();
      localStorage.setItem("token", result.data.accessToken);
     
  
      if (result.data.accessToken) {
        navigate('/dashboard/app', { 
          replace: true,
          state: { successMessage: true } // Pass the success message as state
        });
      }
    } catch (error) {
      // Handle error here
      console.error('Error:', error);
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      setLoading(false); // Ensure loading state is reset regardless of success or error
    }
  };


  return (
    <>
      <Stack spacing={3} sx={{mt:2}}>
        <TextField name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      <Checkbox
          name="remember"
          label="Remember me"
          checked={rememberMe}
          onChange={(e) => handleRememberMeChange(e.target.checked)}
        />
        {/* <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>
      <LoadingButton
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      onClick={handleClick}
      loading={loading} // Use the 'loading' prop to show spinner
      loadingPosition="start" // Position the spinner at the start of the button
      startIcon={loading ? <CircularProgress size={20} /> : null} // Show spinner icon if loading
    >
      Login
    </LoadingButton>
    </>
  );
}
