import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Button,
  Grid,
  CircularProgress
} from '@mui/material';
import './customStyles.css';
import CountriesServices from '../services/CountriesServcies';




export default function AddCountry() {

  const initialUserService = {
    name: "",
    cca2: "",
    currencySymbol: "",
    currency:"",
    phonecode:"",
    region:"",
    subregion:""
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [loading, setLoading] = useState(false);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const saveUser = () => {

        // Check if any of the fields in currentUser are empty
        const isEmptyField = Object.values(currentUser).some(value => value === "");

        if (isEmptyField) {
          // Display a toast message indicating that all fields need to be filled
          toast.error("Please fill in all fields", {
            position: toast.POSITION.TOP_RIGHT
          });
          return; // Don't proceed with the update if fields are empty
        }
    setLoading(true)
    CountriesServices.create(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        console.log(response.data);
        setLoading(false);
        setCurrentUser(initialUserService);
        toast.success("Success : Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT
      })
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false)
      });
  };



  return (
    <>
      <Helmet>
        <title>Add Country | Carib Bargains </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Add New Country
          </Typography>
        </Stack>
        <Card sx={{ m: 1, p: 2, boxShadow: 2, mb: 2, borderRadius: 2 }}>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: 2 }}
        >
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="name"
              label="Name"
             name='name' 
             value={currentUser.name}
                onChange={handleInputChange}

            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="cca2"
              label="Code"
              name='cca2' value={currentUser.cca2}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="currencySymbol"
              label="Currency Symbol"
             name='currencySymbol' value={currentUser.currencySymbol}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="currency"
              label="Currency"
              name='currency' value={currentUser.currency}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="phonecode"
              label="ISD Code"
             name='phonecode' value={currentUser.phonecode}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="region"
              label="Region"
              name='region' value={currentUser.region}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="subregion"
              label="Sub Region"
             name='subregion' value={currentUser.subregion}
                onChange={handleInputChange}
            />
          </Grid>
          </Grid>
           
            <div style={{padding:2, margin:5}}>
              <Button variant="contained" onClick={saveUser} disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </div>
        </Box>
        </Card>
      </Container>
    </>
  );
}
