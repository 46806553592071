import http from "../http-common";
import authHeader from "./auth-header";

const sendNotificationToAllUsers = (data) => {
    return http.post("/Notification/sendall/notification", data);
  };
  
const NotificationService = {
   sendNotificationToAllUsers,
};
export default NotificationService;
