import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress
} from '@mui/material';
import './customStyles.css';
import AppSettings from '../services/AppSettings';




export default function CoinSettings() {

 
  const initialUserService = {
    id: "",
    perAdFee: "",
    featuredAdFee: "",
    coins: "",
    refferedCoins:""
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCoinList = () => {
    setCustomLoader(true);
    AppSettings.getCoins()
      .then((response) => {
        setCurrentUser(response.data.data);
        setCustomLoader(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getCoinList();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true);
       // Check if any of the fields in currentUser are empty
  const isEmptyField = Object.values(currentUser).some(value => value === "");

  if (isEmptyField) {
    // Display a toast message indicating that all fields need to be filled
    toast.error("Please fill in all fields", {
      position: toast.POSITION.TOP_RIGHT
    });
    return; // Don't proceed with the update if fields are empty
  }
    AppSettings.updateCoins(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success("Success : Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT
      })
        console.log(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>Coins Setting | Carib Bargains </title>
      </Helmet>

      <Container>

      {customLoader && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999999999999,
          }}
        >
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            visible={customLoader}
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
          Coins Setting
          </Typography>
        </Stack>
        <Card>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
             <div>
            <TextField fullWidth label="Id" id="id" name='id' value={currentUser.id}
                onChange={handleInputChange} hidden/>
            </div>
            <div>
            <TextField fullWidth label="Add Coins on Registration" id="coins" name='coins' value={currentUser.coins}
                onChange={handleInputChange} />
            </div>
            <div>
            <TextField fullWidth label="Add Coins To Referring Person Of Registration" id="refferedCoins" name='refferedCoins'  value={currentUser.refferedCoins}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Coins For Featured Ads" id="featuredAdFee" name='featuredAdFee'  value={currentUser.featuredAdFee}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Coins For Per Ads" id="perAdFee" name='perAdFee'  value={currentUser.perAdFee}
                onChange={handleInputChange}/>
            </div>
            <div style={{padding:2, margin:5}}>
              <Button variant="contained" onClick={updateUser} disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </div>
        </Box>
        </Card>
      </Container>
    </>
  );
}
