import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress
} from '@mui/material';
import './customStyles.css';
import NotificationService from '../services/NotificationService';

export default function PushNotification() {

  const initialUserService = {
    title: "",
    body: ""
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  
  const updateUser = () => {
    setLoading(true);
    // Check if any of the fields in currentUser are empty
  const isEmptyField = Object.values(currentUser).some(value => value === "");
    if (isEmptyField) {
      // Display a toast message indicating that all fields need to be filled
      toast.error("Please fill in all fields", {
        position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
      return; // Don't proceed with the update if fields are empty
    }


    NotificationService.sendNotificationToAllUsers(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success("Success : Send Successfully", {
          position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
      });
  };
  
  return (
    <>
      <Helmet>
        <title>Push Notification | Carib Bargains </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Send notification to all users
          </Typography>
        </Stack>
        <Card>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
             <div>
            <TextField fullWidth label="Title" id="title" name='title' value={currentUser.title}
                onChange={handleInputChange} />
            </div>
            <div>
            <TextField fullWidth label="Message" id="body" name='body'  value={currentUser.body}
                onChange={handleInputChange}/>
            </div>
            <div style={{padding:2, margin:5}}>
              <Button variant="contained" onClick={updateUser}disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Send'}</Button>
            </div>
        </Box>
        </Card>
      </Container>
    </>
  );
}
