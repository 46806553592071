import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress
} from '@mui/material';
import './customStyles.css';
import AppSettings from '../services/AppSettings';


export default function PaymentSettings() {

  const initialUserService = {
    id: "",
    displayName: "",
    gatewayName: "",
    gatewayLogo: "",
    mechantKey:"",
    secretKey:"",
    salt:"",
    password:"",
    status:"",
    userId:""
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUser = () => {
    setCustomLoader(true);
    AppSettings.getPayment()
      .then((response) => {
        setCurrentUser(response.data.data);
        setCustomLoader(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  useEffect(() => {
     getUser();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true);
    AppSettings.updatePayment(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success("Success : Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
      });
  };



  return (
    <>
      <Helmet>
        <title>Payment Settings | Carib Bargains </title>
      </Helmet>

      <Container>
      {customLoader && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999999999999,
          }}
        >
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            visible={customLoader}
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Payment Setting
          </Typography>
        </Stack>
        <Card>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
             <div>
            <TextField fullWidth label="Id" id="id" name='id' value={currentUser.id}
                onChange={handleInputChange} hidden/>
            </div>
            <div>
            <TextField fullWidth label="Gateway Name" id="gatewayName" name='gatewayName' value={currentUser.gatewayName}
                onChange={handleInputChange} />
            </div>
            <div>
            <TextField fullWidth label="Display Name" id="displayName" name='displayName'  value={currentUser.displayName}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="User Id" id="userId" name='userId'  value={currentUser.userId}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Password" id="password" name='password'  value={currentUser.password}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Secret Key" id="secretKey" name='secretKey'  value={currentUser.secretKey}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Merchant Key" id="mechantKey" name='mechantKey'  value={currentUser.mechantKey}
                onChange={handleInputChange}/>
            </div>
            <div>
            <TextField fullWidth label="Salt" id="salt" name='salt'  value={currentUser.salt}
                onChange={handleInputChange}/>
            </div>
            <div>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Status" sx={{marginLeft: 1}} id="status" name="status" value={currentUser.status}
                onChange={handleInputChange} />
            </FormGroup>
            </div>
            <div style={{padding:2, margin:5}}>
              <Button variant="contained" onClick={updateUser} disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </div>
        </Box>
        </Card>
      </Container>
    </>
  );
}
