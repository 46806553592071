import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Button,
  Grid,
  CircularProgress
} from '@mui/material';
import './customStyles.css';
import AppSettings from '../services/AppSettings';



export default function ApplicationSettings() {

  const initialUserService = {
    id: "",
    fullName: "",
    apikey: "",
    applicationUrl: "",
    apiurl:"",
    facebookUrl:"",
    instagramUrl:"",
    eventImagesUrl:"",
    thumUrl:"",
    reportProblemImagesUrl: "",
    adminUrl:"",
    youtubeUrl:"",
    videoImagesUrl:"",
    profileImagesUrl:"",
    verifyDocumentImagesUrl: "",
    shortName:"",
    baseUrl:""
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUser = () => {
    setCustomLoader(true);
    AppSettings.getApplicationSettings()
      .then((response) => {
        setCurrentUser(response.data.data);
        setCustomLoader(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  useEffect(() => {
     getUser();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true)
    AppSettings.updateApplicationSettings(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        console.log(response.data);
        setLoading(false);
        toast.success("Success : Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT
      })
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false)
      });
  };



  return (
    <>
      <Helmet>
        <title>Application Settings | Carib Bargains </title>
      </Helmet>

      <Container>
      {customLoader && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999999999999,
          }}
        >
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            visible={customLoader}
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Application Setting
          </Typography>
        </Stack>
        <Card sx={{ m: 1, p: 2, boxShadow: 2, mb: 2, borderRadius: 2 }}>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: 2 }}
        >
            <div> 
            <TextField
              fullWidth
              id="id"
              label="Id"
            name='id' 
             value={currentUser.id}
                onChange={handleInputChange}
                hidden
            />
            </div>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="fullName"
              label="Full Name"
             name='fullName' 
             value={currentUser.fullName}
                onChange={handleInputChange}

            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="shortName"
              label="Short Name"
              name='shortName' value={currentUser.shortName}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="applicationUrl"
              label="Application Url"
             name='applicationUrl' value={currentUser.applicationUrl}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="apikey"
              label="API Key"
              name='apikey' value={currentUser.apikey}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="apiurl"
              label="API Url"
             name='apiurl' value={currentUser.apiurl}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="adminUrl"
              label="Admin Url"
              name='adminUrl' value={currentUser.adminUrl}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="facebookUrl"
              label="Facebook Url"
             name='facebookUrl' value={currentUser.facebookUrl}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="youtubeUrl"
              label="Youtube Url"
              name='youtubeUrl' value={currentUser.youtubeUrl}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="instagramUrl"
              label="Instagram Url"
             name='instagramUrl' value={currentUser.instagramUrl}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="baseUrl"
              label="Base Url"
              name='baseUrl' value={currentUser.baseUrl}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="thumUrl"
              label="Thum Url"
             name='thumUrl' value={currentUser.thumUrl}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="reportProblemImagesUrl"
              label="Report Problem Images Url"
              name='reportProblemImagesUrl' value={currentUser.reportProblemImagesUrl}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="profileImagesUrl"
              label="Profile Images Url"
             name='profileImagesUrl' value={currentUser.profileImagesUrl}
                onChange={handleInputChange}
            />
          </Grid>

          </Grid>
           
            <div style={{padding:2, margin:5}}>
              <Button variant="contained" onClick={updateUser} disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </div>
        </Box>
        </Card>
      </Container>
    </>
  );
}
