import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0.00 0.00 57.00 58.00">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
        <path fill="#2a89ba" d="
  M 36.79 11.01
  C 41.82 16.06 42.56 24.26 38.57 29.90
  C 29.84 42.25 10.23 35.39 11.68 20.09
  C 12.49 11.58 18.73 6.90 27.02 6.96
  Q 39.49 7.05 51.93 6.98
  Q 52.50 6.98 52.50 7.55
  L 52.50 9.87
  Q 52.50 10.50 51.88 10.50
  L 37.01 10.50
  Q 36.28 10.50 36.79 11.01
  Z
  M 37.23 21.48
  A 10.88 10.88 0.0 0 0 26.35 10.60
  A 10.88 10.88 0.0 0 0 15.47 21.48
  A 10.88 10.88 0.0 0 0 26.35 32.36
  A 10.88 10.88 0.0 0 0 37.23 21.48
  Z"
/>
<path fill="#ebad1e" d="
  M 48.17 22.19
  C 44.67 50.57 6.34 49.62 4.33 22.16
  Q 4.28 21.50 4.95 21.50
  L 7.70 21.50
  Q 8.22 21.50 8.30 22.02
  C 11.56 44.83 41.19 44.93 43.95 22.15
  Q 44.03 21.50 44.69 21.50
  L 47.56 21.50
  Q 48.25 21.50 48.17 22.19
  Z"
/>
<circle fill="#eb3b23" cx="17.58" cy="49.74" r="3.41"/>
<circle fill="#eb3b23" cx="36.50" cy="49.72" r="3.43"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
