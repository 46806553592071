// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


// Define the submenu items for the "Application Settings"
const applicationSettingsSubmenu = [
  {
    title: 'Company Setting',
    path: '/dashboard/companysettings',
    icon: icon('business-and-trade'),
  },
  {
    title: 'Application Setting',
    path: '/dashboard/applicationsettings',
    icon: icon('feature'),
  },
  {
    title: 'Developer Setting',
    path: '/dashboard/devsettings',
    icon: icon('coding'),
  },
  {
    title: 'Coins Setting',
    path: '/dashboard/coinssettings',
    icon: icon('profit'),
  },
  {
    title: 'Payment Setting',
    path: '/dashboard/paymentsettings',
    icon: icon('wallet'),
  },
  {
    title: 'SMTP Setting',
    path: '/dashboard/smtpsettings',
    icon: icon('email'),
  },
  {
    title: 'Notification Setting',
    path: '/dashboard/notificationssettings',
    icon: icon('notification'),
  },
];

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'Categories',
    path: '/dashboard/categories',
    icon: icon('maintenance'),
  },
  {
    title: 'Countries',
    path: '/dashboard/countries',
    icon: icon('country'),
  },
  {
    title: 'Notify User (Push)',
    path: '/dashboard/notification',
    icon: icon('notifications'),
  },
  {
    title: 'Settings',
    icon: icon('setting'),
    hasSubmenu: true,
    submenu: applicationSettingsSubmenu, // Add the submenu array here
  },

];

export default navConfig;
