import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link as RouterLink, useParams} from 'react-router-dom';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Box,
  styled,
  Pagination,
  Avatar,
  Switch,
  Grid,
  TextField,
  CircularProgress
} from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import FormControlLabel from '@mui/material/FormControlLabel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import './customStyles.css';
import { DataGrid, GridPagination, GridToolbar,  GridFooterContainer, } from '@mui/x-data-grid';
import CountriesServices from '../services/CountriesServcies';
// components
import Iconify from '../components/iconify';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const Fade = React.forwardRef((props, ref) => {
    const {
      children,
      in: open,
      onClick,
      onEnter,
      onExited,
      ownerState,
      ...other
    } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter(null, true);
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited(null, true);
        }
      },
    });
  
    return (
      <animated.div ref={ref} style={style} {...other}>
        {React.cloneElement(children, { onClick })}
      </animated.div>
    );
  });
  
  
  Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
  };
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Countries() {

 // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  
  const [showproduct, setShowproduct] = useState(false);
  const handleCloseproduct = () => setShowproduct(false);
  const handleShowproduct = () => setShowproduct(true);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const handleCloseEditProduct = () => setShowEditProduct(false);
  const handleShowEditProduct = () => setShowEditProduct(true);

  const [data, setdata] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
        field: "name",
        headerName: "Name",
        width: 200,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
      },
    {
      field: "cca2",
      headerName: "Code",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "phonecode",
      headerName: "ISD Code",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "currencySymbol",
      headerName: "Symbol",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
        field: 'status',
        headerName: 'Is Active',
        width: 120,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => {
            const toggleStatus = async () => {
                setLoading(true);
                const newStatus = !params.value; // Toggle the status
              
                const url = `http://62.171.153.83:8080/carib-bargains-api-new/api/location/mycountrStatus/${params.row.id}/${newStatus}`;
              
                try {
                  const response = await fetch(url, {
                    method: 'PUT', // You might need to adjust the method
                  });
              
                  if (response.ok) {
                    // Update your local data or state to reflect the new status
                    // For example:
                    const updatedData = data.map(row =>
                      row.id === params.row.id ? { ...row, status: newStatus } : row
                    );
                    setdata(updatedData);
              
                    // Update the checkbox value
                    params.value = newStatus;
              
                    toast.success("Success: Updated Successfully", {
                      position: toast.POSITION.TOP_RIGHT
                    });
                  } else {
                    toast.error("Error: Status Not Changed", {
                      position: toast.POSITION.TOP_RIGHT
                    });
                    console.error('Status update failed');
                  }
                } catch (error) {
                  toast.error("Something went wrong!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  console.error('Error updating status:', error);
                }
              
                setLoading(false);
              };
              
    
          return (
            <FormControlLabel
              control={<IOSSwitch  checked={params.value}  onChange={toggleStatus} sx={{ m: 1 }} />}
            />
          );
        },
      },
      {
        field: "Action",
        width: 200,
        sortable: false,
        headerAlign: "center",
        renderCell: (params) => {
          const onClickView = async () => {
            try {
              const response = await fetch(
                `http://62.171.153.83:8080/carib-bargains-api-new/api/location/country/${params.row.id}`,
                {
                  method: "GET",
                }
              );
      
              if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
      
                // Assuming you have a state variable named 'selectedUserData'
                setCurrentUser(responseData.data); // Update state with fetched data
              } else {
                console.error("Error fetching user details:", response.status);
              }
            } catch (error) {
              console.error("Fetch error:", error);
            }
          };

          const onClickEdit = async () => {
            try {
              const response = await fetch(
                `http://62.171.153.83:8080/carib-bargains-api-new/api/location/country/${params.row.id}`,
                {
                  method: "GET",
                }
              );
      
              if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
      
                // Assuming you have a state variable named 'selectedUserData'
                setCurrentUser(responseData.data); // Update state with fetched data
              } else {
                console.error("Error fetching user details:", response.status);
              }
            } catch (error) {
              console.error("Fetch error:", error);
            }
          };
      
          return (
            <>
              <Button
                onClick={()=>{
                  onClickView()
                  handleShowproduct()
                }}
                variant="contained"
                size="small"
                color="warning"
                style={{ marginLeft: 16 }}
                startIcon={<RemoveRedEyeIcon />}
              >
                View
              </Button>
              <Button
                onClick={()=>{
                  onClickEdit()
                  handleShowEditProduct()
                }}
                variant="contained"
                size="small"
                color="primary"
                style={{ marginLeft: 16 }}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </>
          );
        },
      },

  ];

  const initialUserService = {
    id: "",
    name: "",
    cca2: "",
    phonecode: "",
    currency:"",
    currencySymbol: "",
    region:"",
    subregion:"",
    status: "",

  };

  const [currentUser, setCurrentUser] = useState(initialUserService);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageSizes = [10, 25, 50]; 
  const { id } = useParams();


  const getCountry = (id) => {
    setLoading(true);
    CountriesServices.getCountry(id)
      .then((response) => {
        setCurrentUser(response.data);
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) getCountry(id);
  }, [id]);

  const getRequestParams = ( page, pageSize) => {
    const paramss = {};
    if (page) {
      paramss.page = page - 1;
    } 
    if (pageSize) {
      paramss.size = pageSize;
    }
    return paramss;
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const handlePageSizeChange = (event) => {
    setPageSize(event);
    setPage(1);
  };

  const retrieveTutorials = () => {
    setLoading(true);
    const params = getRequestParams(
      page,
      pageSize
    );

    CountriesServices.getPaginationWithCountries(params)
      .then((response) => {
        const { country, totalPages, totalItems } = response.data.data;
        if (country === 0 || country === undefined) {
          setdata(0);
        } else {
          setdata(country);
        }
        setTotalItems(totalItems);
        setCount(totalPages);
        setLoading(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  // eslint-disable-next-line
  useEffect(retrieveTutorials, [page, pageSize]);

  function CustomPagination() {
    return (
      <>
        <Pagination
          className="my-3"
          color="secondary"
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <Box>
          <div className="float-right">
            Showing the first {pageSize * (page - 1) + 1} -{" "}
            {totalItems > pageSize * (page - 1) + pageSize
              ? pageSize * (page - 1) + pageSize
              : totalItems}{" "}
            results of {totalItems} rows
          </div>
          <Typography>
            Page: {page} of {count}
          </Typography>
        </Box>
      </>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer>
        <CustomPagination />
        <GridPagination />
      </GridFooterContainer>
    );
  }

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true)
    CountriesServices.updateCountry(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        console.log(response.data);
        setLoading(false);
        toast.success("Success : Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT
      })
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false)
      });
  };

  

  return (
    <>
      <Helmet>
        <title> Countries | Carib Bargains </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
          Countries
          </Typography>
              <Button
              component={RouterLink}
              to="/dashboard/addcountries"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Add Country
              </Button>
        </Stack>

        <Card>
          
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          // sx={{
          //   boxShadow: 2,
          //   border: 2,
          //   borderColor: "primary.light",
          //   "& .MuiDataGrid-cell:hover": {
          //     color: "primary.main",
          //   },
          // }}
          rows={data}
          disableColumnMenu
          disableSelectionOnClick
          columns={columns}
          onSelectionModelChange={setSelectionModel}
          selectionModel={selectionModel}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={pageSizes}
          preProcessEditCellProps
          localeText={{
            footerRowSelected: CustomPagination,
          }}
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
            Footer: CustomFooter,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          {...data}
        />
      </Box>
        </Card>
        <Modal show={showproduct} onHide={handleCloseproduct} size="lg" style={{marginLeft:"2%"}}>
        <Modal.Header closeButton>
          <Modal.Title>Country Id: {currentUser.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ borderBottom: "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)" }}>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between">
            <Stack style={{ width: "50%" }}><b>Country Name:</b> {currentUser.name}</Stack>
            <Stack style={{ width: "50%" }}><b>Country Code:</b> {currentUser.cca2}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between">
            <Stack style={{ width: "50%" }}><b>ISD Code:</b> {currentUser.phonecode}</Stack>
            <Stack style={{ width: "50%" }}><b>Symbol:</b> {currentUser.currencySymbol}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between">
            <Stack style={{ width: "50%" }}><b>Currency:</b> {currentUser.currencySymbol}</Stack>
            <Stack style={{ width: "50%" }}><b>Region:</b> {currentUser.region}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between">
            <Stack style={{width: "50%" }}><b>Created At:</b> {currentUser.createdAt}</Stack>
            <Stack style={{width: "50%" }}><b>Updated At:</b> {currentUser.updatedAt}</Stack>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseproduct}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseproduct}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>


      <Modal show={showEditProduct} onHide={handleCloseEditProduct} size="lg" style={{marginLeft:"2%"}}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Country</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ borderBottom: "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)" }}>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: 2 }}
        >
            <div> 
            <TextField
              fullWidth
              id="id"
              label="Id"
            name='id' 
             value={currentUser.id}
                onChange={handleInputChange}
                hidden
            />
            </div>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="name"
              label="Country Name"
             name='name' 
             value={currentUser.name}
                onChange={handleInputChange}

            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="cca2"
              label="Code"
              name='cca2' value={currentUser.cca2}
              onChange={handleInputChange}
            />
          </Grid>


          <Grid item xs={6}>
            <TextField
              fullWidth
              id="currencySymbol"
              label="Currency Symbol"
              name='currencySymbol' value={currentUser.currencySymbol}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="currency"
              label="Currency"
              name='currency' value={currentUser.currency}
              onChange={handleInputChange}
            />
          </Grid>


          <Grid item xs={6}>
            <TextField
              fullWidth
              id="phonecode"
              label="ISD Code"
             name='phonecode' value={currentUser.phonecode}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="region"
              label="Region"
             name='region' value={currentUser.region}
                onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="subregion"
              label="Sub Region"
              name='subregion' value={currentUser.subregion}
              onChange={handleInputChange}
            />
          </Grid>
          </Grid>
        </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditProduct}>
            Close
          </Button>
          <Button variant="contained" onClick={updateUser} disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Save'}</Button>

        </Modal.Footer>
      </Modal>
      </Container>
    </>
  );
}
